<template>
  <div>
    <div class="header__top-container container">
      <div class="header__top-content">
        <div class="tm-head-top__left">
          <div class="header__top-button-back-call--content">
            <nuxt-link
              to="/email"
              class="button button-default header__top-button-back-call"
            >
              <span class="header__top-button-back--pc">Заказать обратный звонок</span>
              <span class="header__top-button-back-call--mobile">Обратный звонок</span>
            </nuxt-link>
          </div>

          <div
            class="address"
            @click="openSelectCityModal"
          >
            <img
              :src="LocationIcon"
              alt="location icon"
            >
            <span title="Если город определился не правильно, то нажмите, чтобы выбрать верный вариант.">{{ currentCity }} </span>
          </div>

          <ul class="header__cms-pages">
            <li
              v-for="(item, idx) in menu"
              :key="`header__cms-pages-item-${idx}`"
              class="header__cms-pages-item"
            >
              <nuxt-link :to="`/${item.path}`">{{ item.title }}</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="tm-hed-top__right">
          <div class="header__social">
            <nuxt-link
              v-if="socialLink('odnoklassniki') !== ''"
              :to="socialLink('odnoklassniki')"
              class="social"
              target="_blank"
            >
              <img
                :src="PinterestIcon"
                alt="pinterest icon"
              >
            </nuxt-link>
            <nuxt-link
              v-if="socialLink('facebook') !== ''"
              class="social prohibitedSocial"
              :to="socialLink('facebook')"
              target="_blank"
            >
              <img
                :src="FacebookBlackIcon"
                alt="facebook"
              >
            </nuxt-link>
            <div class="tooltip">{{tooltipText}}</div>
            <nuxt-link
              v-if="socialLink('instagram') !== ''"
              class="social prohibitedSocial"
              :to="socialLink('instagram')"
              target="_blank"
            >
              <img
                :src="InstagramBlackIcon"
                alt="instagram"
              >
            </nuxt-link>
            <div class="tooltip">{{tooltipText}}</div>
          </div>
          <div
            class="header__search"
            @click="toggleSearch"
          >
            <span>Поиск</span>
            <img
              :src="SearchIcon"
              alt="search icon"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import LocationIcon from '~/assets/img/svg/header/marker.svg';
import SearchIcon from '~/assets/img/svg/header/search.svg';
import PinterestIcon from '~/assets/img/svg/header/pinterest.svg';
import FacebookBlackIcon from '~/assets/img/svg/header/facebook-black.svg';
import InstagramBlackIcon from '~/assets/img/svg/header/instagram-black.svg';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { storeToRefs } from 'pinia';
import { useCitySelectModal } from '#imports';

const emit = defineEmits<{
	toggleSearch: [],
	openCityPopup:[],
}>();

const cmsStore = cmsContentStore();
const appConfigStore1 = appConfigStore();

const { menu } = storeToRefs(cmsStore);
const { config } = storeToRefs(appConfigStore1);

const currentCity = computed(() => config?.value?.city?.model?.name || 'Екатеринбург');
const toggleSearch = () => {
  emit('toggleSearch');
};
const { openSelectCityModal } = useCitySelectModal();

const socialLink = function(socialName: string){
  if (socialName==='instagram') {
    return config.value.instagram || '';
  }
  if (socialName === 'facebook') {
    return config.value.facebook || '';
  }
  if (socialName === 'odnoklassniki') {
    return config.value.odnoklassniki || '';
  }
}

const tooltipText = 'Признана экстремисткой организацией и запрещена на территории Российской Федерации';
</script>

<style lang="scss">
.header__top-content {
  display: flex;
  padding: 1px 0;
  justify-content: space-between;
}

.tm-head-top__left {
  display: flex;
  align-items: center;
}

.header__top-button-back-call--content {
  display: flex;
  align-items: center;
  margin-right: 19px;
}

.header__top-button-back-call--mobile {
  display: none;
}

.header__top-button-back-call {
  font-size: 12px;
  line-height: 12px;
  padding: 5px 10px;
  font-weight: 700;
  border-width: 1px;
}

header .address {
  color: #fe1a00;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-right: 19px;

  & img {
    transition: all 0.5s;
    width: 12px;
    margin-right: 5px;
  }

  &:hover img {
    transform: translate(0, -3px) scale(1.1);
  }
}

.header__cms-pages {
  display: flex;
  list-style: none;
  padding: 0;

  //margin-right: -10px;
  margin: 0;
}

.header__cms-pages-item {
  margin-right: 19px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  & a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.tm-hed-top__right {
  display: flex;
  align-items: center;
}

.header__social {
  font-size: 14px;
  display: flex;
  margin-right: 20px;

  & .social {
    width: 28px;
    height: 32px;
    display: block;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px;

    &:hover {
      opacity: 0.5;
      background-color: transparent;
    }
  }

  & img {
    width: 100%;
    height: 100%;
  }
  .tooltip {
    position: absolute;
    display: none;
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
    top: 30px;
    max-width: 250px;
    font-size: 10px;
  }
  a:hover + .tooltip {
    display: block;
  }
}

.header__search {
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;

  &:hover img {
    transform: translate(0, -3px) scale(1.1);
  }

  & img {
    margin-left: 6px;
    transition: all 0.5s;
  }
}

@media screen and (max-width: 1299px) {
  .header__cms-pages-item {
    margin-right: 15px;
  }

  .header__top-button-back-call--content {
    margin-right: 10px;
  }

  .header__social {
    margin-right: 15px;
  }
}

@media screen and (max-width: 1250px) {
  .header__cms-pages-item {
    font-size: 13px;
  }
}

@media screen and (max-width: 1199px) {
  .header__top-button-back--pc {
    display: none;
  }

  .header__top-button-back-call--mobile {
    display: block;
  }

  .header__cms-pages-item {
    margin-right: 10px;
    font-size: 11px;
  }

  .header__social {
    margin-right: 10px;
  }
}

@media screen and (max-width: 990px) {
  .header__top-container {
    display: none;
  }

  .header__body--hide-medium {
    display: none !important;
  }
}
</style>
