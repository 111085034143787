<template>
  <div class="header__footer">
    <div class="container">
      <div class="header__footer-content">
        <nav>
          <ul class="header__menu">
            <template
              v-for="(firstMenu, idx) in newMenuList"
              :key="`firsMenu-${idx}`"
            >
              <li
                v-if="firstMenu.showInMenu"
                class="header__menu-item"
                @mouseenter="onShowMenuList(firstMenu)"
                @mouseleave="onHideMenuList"
              >
                <nuxt-link
                  :class="{'!text-red-600': firstMenu.url === $route.fullPath}"
                  :to="firstMenu.url"
                  class="tm-firstMenu-nav"
                  @click="onHideMenuList"
                >
                  {{ firstMenu.name }}
                </nuxt-link>

                <div class="header__menu-item-dropdown">
                  <div class="container">
                    <div class="dropdown-menu">
                      <template v-if="!isProductsLoading">
                        <template v-if="productsList.length && isShowMenuList">
                          <div
                            v-for="(secondMenu, idx2 ) in productsList"
                            :key="`dropdown-menu-item-${idx2}`"
                            class="dropdown-menu-item"
                          >
                            <div class="dropdown-menu-item__image-content">
                              <NuxtImg
                                width="210"
                                height="160"
                                loading="lazy"
                                fit="cover"
                                class="dropdown-menu-item__image"
                                :src="String(secondMenu?.imageId)"
                              />
                            </div>
                            <div class="dropdown-menu-item__name">{{ secondMenu.name }}</div>
                            <nuxt-link
                              class="dropdown-menu-item__link"
                              :to="secondMenu.url"
                              @click="onHideMenuList"
                            />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <div
                          v-for="loading in 12"
                          :key="`loading-menu-${loading}`"
                          class="loader-menu loading-indicator"
                        >
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import type { ICategory } from '#sitis/internal/controllers/categories/models/Category';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
const { $api } = useNuxtApp();

const catStore = catalogStore();
const { catalogTree } = storeToRefs(catStore);

const isShowMenuList = ref(false);
const timeOutOpenMenu = ref<ReturnType<typeof setTimeout>>();
const newMenuList = computed(() => catalogTree?.value?.[0]?.items || []);
const productsList = ref<IProduct[]>([]);
const isProductsLoading = ref<boolean>(false);
const onShowMenuList = async (category:ICategory) => {
  clearTimeout(timeOutOpenMenu.value);
  if (isShowMenuList.value) {
    return;
  }
  if (category.dynamicFields.show_products_in_menu) {
   timeOutOpenMenu.value = setTimeout(async () => {
     isShowMenuList.value = true;
     await onGetProducts(category);
   }, 100);
  }
};

const onHideMenuList = () => {
  clearTimeout(timeOutOpenMenu.value);
  isShowMenuList.value = false;
};
async function onGetProducts (category:ICategory) {
  isProductsLoading.value = true;

  const res = await $api.productsApi.getProducts(category.slug, {
    basePath: '/categories'
  }).catch((err) => {
    console.log(err);
  });

  if (res && res.items) {
    productsList.value = res.items;
  }

  isProductsLoading.value = false;
}
</script>

<style lang="scss">
.header__footer {
  position: relative;
  border-bottom: 1px solid #fe1a00;
}

.header__menu {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__menu-item {
  padding: 30px 12px;
  cursor: pointer;

  &:hover > a {
    color: var(--primary);
  }

  &:hover .header__menu-item-dropdown {
    visibility: visible;
    opacity: 1;
    transition: all linear .3s;
  }

  & a {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    transition: transform .5s;
  }
}

.header__menu-item-dropdown {
  position: absolute;
  top: 100%;
  background: #fff;
  width: 100%;
  left: 0;
  z-index: 999;
  box-shadow: 0 10px 10px -10px rgb(0 0 0 / 25%);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all linear .5s;
}

.dropdown-menu {
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 0 10px;
}
.loader-menu,
.dropdown-menu-item {
  width: calc(100% / 6 - 15px);
  margin-left: 15px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  transition: all linear .2s;

  &:hover .dropdown-menu-item__name {
    color: red;
    transition: all linear .2s;
  }
}

.loader-menu{
	min-height: 160px;
}

.dropdown-menu-item__image-content {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  &::before {
    content: '';
    padding-top: 70%;
    float: left;
  }
}

.dropdown-menu-item__image {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  position: absolute;
}

.dropdown-menu-item__name {
  font-size: 18px;
  line-height: 21px;
  color: #000;
}

.dropdown-menu-item__link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@media screen and (max-width: 1199px) {
  .header__menu-item {
    & a {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: 990px) {
  .header__footer {
    display: none;
  }
}
</style>
