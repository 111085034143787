import { default as _indexasFTDyBbK2Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/_index.vue?macro=true";
import { default as _91_46_46_46cms_93E4mFv5MNmuMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/[...cms].vue?macro=true";
import { default as indexd6wVKc9MbfMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/cart/index.vue?macro=true";
import { default as indexJxoHSPekEWMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93vEpY4dOvjsMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/catalog/[...slug].vue?macro=true";
import { default as indexbZw8vfMcrpMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93sIYk2afQrMMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/collection/[...slug].vue?macro=true";
import { default as indexqq6Lb4PiO7Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/email/index.vue?macro=true";
import { default as index5Z15Zpc3k0Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/kontakty/index.vue?macro=true";
import { default as _91_46_46_46slug_938DoEYnNBLHMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/product/[...slug].vue?macro=true";
import { default as index11r18nQUy7Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/search/index.vue?macro=true";
import { default as indexn4hqwidcYEMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _indexasFTDyBbK2Meta?.name ?? "_index",
    path: _indexasFTDyBbK2Meta?.path ?? "/_index",
    meta: _indexasFTDyBbK2Meta || {},
    alias: _indexasFTDyBbK2Meta?.alias || [],
    redirect: _indexasFTDyBbK2Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/_index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46cms_93E4mFv5MNmuMeta?.name ?? "cms",
    path: _91_46_46_46cms_93E4mFv5MNmuMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93E4mFv5MNmuMeta || {},
    alias: _91_46_46_46cms_93E4mFv5MNmuMeta?.alias || [],
    redirect: _91_46_46_46cms_93E4mFv5MNmuMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: indexd6wVKc9MbfMeta?.name ?? "cart",
    path: indexd6wVKc9MbfMeta?.path ?? "/cart",
    meta: indexd6wVKc9MbfMeta || {},
    alias: indexd6wVKc9MbfMeta?.alias || [],
    redirect: indexd6wVKc9MbfMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexJxoHSPekEWMeta?.name ?? "cart-success",
    path: indexJxoHSPekEWMeta?.path ?? "/cart/success",
    meta: indexJxoHSPekEWMeta || {},
    alias: indexJxoHSPekEWMeta?.alias || [],
    redirect: indexJxoHSPekEWMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93vEpY4dOvjsMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93vEpY4dOvjsMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93vEpY4dOvjsMeta || {},
    alias: _91_46_46_46slug_93vEpY4dOvjsMeta?.alias || [],
    redirect: _91_46_46_46slug_93vEpY4dOvjsMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexbZw8vfMcrpMeta?.name ?? "catalog",
    path: indexbZw8vfMcrpMeta?.path ?? "/catalog",
    meta: indexbZw8vfMcrpMeta || {},
    alias: indexbZw8vfMcrpMeta?.alias || [],
    redirect: indexbZw8vfMcrpMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93sIYk2afQrMMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93sIYk2afQrMMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93sIYk2afQrMMeta || {},
    alias: _91_46_46_46slug_93sIYk2afQrMMeta?.alias || [],
    redirect: _91_46_46_46slug_93sIYk2afQrMMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexqq6Lb4PiO7Meta?.name ?? "email",
    path: indexqq6Lb4PiO7Meta?.path ?? "/email",
    meta: indexqq6Lb4PiO7Meta || {},
    alias: indexqq6Lb4PiO7Meta?.alias || [],
    redirect: indexqq6Lb4PiO7Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/email/index.vue").then(m => m.default || m)
  },
  {
    name: index5Z15Zpc3k0Meta?.name ?? "kontakty",
    path: index5Z15Zpc3k0Meta?.path ?? "/kontakty",
    meta: index5Z15Zpc3k0Meta || {},
    alias: index5Z15Zpc3k0Meta?.alias || [],
    redirect: index5Z15Zpc3k0Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/kontakty/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938DoEYnNBLHMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_938DoEYnNBLHMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_938DoEYnNBLHMeta || {},
    alias: _91_46_46_46slug_938DoEYnNBLHMeta?.alias || [],
    redirect: _91_46_46_46slug_938DoEYnNBLHMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index11r18nQUy7Meta?.name ?? "search",
    path: index11r18nQUy7Meta?.path ?? "/search",
    meta: index11r18nQUy7Meta || {},
    alias: index11r18nQUy7Meta?.alias || [],
    redirect: index11r18nQUy7Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexn4hqwidcYEMeta?.name ?? "wishlist",
    path: indexn4hqwidcYEMeta?.path ?? "/wishlist",
    meta: indexn4hqwidcYEMeta || {},
    alias: indexn4hqwidcYEMeta?.alias || [],
    redirect: indexn4hqwidcYEMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20240722091530/pages/wishlist/index.vue").then(m => m.default || m)
  }
]