<template>
  <div class="wrapper">
    <div class="header-wrapper">
      <TheHeader />
    </div>
    <NuxtLayout>
      <main class="main">
        <ErrorContent
          :error="error"
        />
      </main>
    </NuxtLayout>
    <div class="footer-wrapper">
      <TheFooter />
    </div>


    <Link rel="canonical" :href="linkCanonical"/>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { catalogStore } from '#sitis/stores/modules/catalog-store';

import { useRoute } from '#app';
import TheHeader from '~/components/common/header/TheHeader.vue';
import TheFooter from '~/components/common/footer/TheFooter.vue';
import ScrollToTopButton from '~/components/common/ScrollToTopButton.vue';
import useGalleryComponent from "~/composables/useGallery";

import ErrorContent from '~/components/error/ErrorContent.vue';

const props = defineProps({
  error: {
    type: Object as any
  }
});

const storeCatalog = catalogStore();
const cmsStore = cmsContentStore();
const configStore = appConfigStore();

const $route = useRoute();
const scY = ref<number>(0);
const scTimer = ref<ReturnType<typeof setTimeout>>();

onServerPrefetch(async () => {
  await cmsStore.getMenu();
  await configStore.getConfig();
  await storeCatalog.getCatalog();
});

useHead({
  title: 'Cilec',
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1 user-scalable=no' },
    { name: 'description', content: 'my content' }
  ],
  link: [
    // { rel: 'icon', type: 'image/svg+xml', href: `${configStore?.configList?.favicon}` },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', href: '/favicon-32x32.png' },
    { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
    { rel: 'shortcut icon', color: '#5bbad5', href: '/favicon.ico' }
  ]
});


const { isGalleryOpen } = useGalleryComponent();

const isHome = computed(() => $route.fullPath === '/');
const countersFooter = computed(() => configStore?.$state?.config?.counters?.footer || '');

const handleScroll = () => {
  if (scTimer.value) {
    return null;
  }

  scTimer.value = setTimeout(() => {
    scY.value = window.scrollY;
    clearTimeout(scTimer.value);
    scTimer.value = undefined;
  }, 100);
};

const linkCanonical: ComputedRef<string> = computed(() => {
  const event = useRequestEvent();
  const host = process.server ? event?.node.req.headers.host || '' : window.location.host;
  const [path] = useRoute().fullPath.split('?');
  return `https://${host}${path}`
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
</script>

<style lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  padding: 22px 0 40px;
  min-height: 100%;
  flex: 1 0 auto;

  &.home {
    padding: 0 0 40px;
  }
}

.footer-wrapper {
  width: 100%;
  flex: 0 0 auto;
}

</style>
