import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordionGalleryBlock, LazyAdvantagesBlock, LazyBannerBlock, LazyBannerCollectionsBlock, LazyBlocksListBlock, LazyBlocksMosaicBlock, LazyContactsBlock, LazyFormBlock, LazyHeadingBlock, LazyHtmlBlock, LazyLastSeenProductsBlock, LazyLinksListBlock, LazyLinksWithImagesBlock, LazyReviewsBlock, LazySelectProductsBlock, LazyShareBlock, LazySpaceBlock, LazyStoresBlock, LazySubscribeBlock, LazyTextInformationBlock, LazyTitleBlock, LazyTwoColumnsBlock, LazyVideoBlock, LazyNewAccordionGalleryBlock, LazyMainLayout, LazyCmsBlockMixin, LazyCmsPage, LazyCarouselBlock, LazyCkEditorBlock, LazyContainerBlock, LazyGridBlock, LazyImageBlock, LazySectionBlock, LazySpecialBlock, LazyLayoutPlaceholder, LazyHeaderNavItem, LazyTestHeaderNav, LazyPopupGallery, LazyLazySearchDropdown, LazySearchInputBody, LazySearchInputTop, LazyArticles, LazyBrands, LazyCategories, LazyProductCard, LazyProducts, LazyService, LazyVideos, LazyComponent, LazyAddToCardBtn, LazyProductCardTemplate, LazyProductPageTemplate, LazyPagination } from '#components'
const lazyGlobalComponents = [
  ["AccordionGalleryBlock", LazyAccordionGalleryBlock],
["AdvantagesBlock", LazyAdvantagesBlock],
["BannerBlock", LazyBannerBlock],
["BannerCollectionsBlock", LazyBannerCollectionsBlock],
["BlocksListBlock", LazyBlocksListBlock],
["BlocksMosaicBlock", LazyBlocksMosaicBlock],
["ContactsBlock", LazyContactsBlock],
["FormBlock", LazyFormBlock],
["HeadingBlock", LazyHeadingBlock],
["HtmlBlock", LazyHtmlBlock],
["LastSeenProductsBlock", LazyLastSeenProductsBlock],
["LinksListBlock", LazyLinksListBlock],
["LinksWithImagesBlock", LazyLinksWithImagesBlock],
["ReviewsBlock", LazyReviewsBlock],
["SelectProductsBlock", LazySelectProductsBlock],
["ShareBlock", LazyShareBlock],
["SpaceBlock", LazySpaceBlock],
["StoresBlock", LazyStoresBlock],
["SubscribeBlock", LazySubscribeBlock],
["TextInformationBlock", LazyTextInformationBlock],
["TitleBlock", LazyTitleBlock],
["TwoColumnsBlock", LazyTwoColumnsBlock],
["VideoBlock", LazyVideoBlock],
["NewAccordionGalleryBlock", LazyNewAccordionGalleryBlock],
["MainLayout", LazyMainLayout],
["CmsBlockMixin", LazyCmsBlockMixin],
["CmsPage", LazyCmsPage],
["CarouselBlock", LazyCarouselBlock],
["CkEditorBlock", LazyCkEditorBlock],
["ContainerBlock", LazyContainerBlock],
["GridBlock", LazyGridBlock],
["ImageBlock", LazyImageBlock],
["SectionBlock", LazySectionBlock],
["SpecialBlock", LazySpecialBlock],
["LayoutPlaceholder", LazyLayoutPlaceholder],
["HeaderNavItem", LazyHeaderNavItem],
["TestHeaderNav", LazyTestHeaderNav],
["PopupGallery", LazyPopupGallery],
["LazySearchDropdown", LazyLazySearchDropdown],
["SearchInputBody", LazySearchInputBody],
["SearchInputTop", LazySearchInputTop],
["Articles", LazyArticles],
["Brands", LazyBrands],
["Categories", LazyCategories],
["ProductCard", LazyProductCard],
["Products", LazyProducts],
["Service", LazyService],
["Videos", LazyVideos],
["Component", LazyComponent],
["AddToCardBtn", LazyAddToCardBtn],
["ProductCardTemplate", LazyProductCardTemplate],
["ProductPageTemplate", LazyProductPageTemplate],
["Pagination", LazyPagination],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
