<template>
  <header :class="{ 'header-sticky': isFixedHeader }">
    <HeaderDesktopTop
      @toggleSearch="toggleSearch"
    />

    <HeaderDesktopMiddle
      :is-fixed-header="isFixedHeader"
      :is-show-search="isShowSearch"

      @openMobileWhatsApp="openMobileWhatsApp"
      @closeSearch="closeSearch"
    />

    <HeaderDesktopBottom />

    <MobileMenu
      v-if="isMobileMenuOpen"
      :menu="menuList"
      :catalog-tree="catalogTree"
    />
    <SelectCityPopup v-if="isSelectCityModalOpen" />

    <!--            <HeaderWhatsapp :isOpenMobileWhatsaap="isOpenMobileWhatsaap"/>-->


    <teleport to="body">
      <div
        v-if="isOpenMobileWhatsApp"
        class="header-mobile-phones"
        :class="{'--fixed': isFixedHeader}"
      >
        <a
          v-if="numberPhone"
          :href="`tel:${getPhoneNumberLink(numberPhone)}`"
          target="_blank"
          class="--item"
        >
          <div class="--item__icon">
            <img src="~/assets/img/svg/header/phone-red.svg"/>
          </div>
          <div class="--item__number">
            {{ getPhoneNumberFormat(numberPhone) }}
          </div>
        </a>
        <a
          v-if="numberWhatsap"
          :href="`tel:${getPhoneNumberLink(numberWhatsap)}`"
          target="_blank"
          class="--item"
        >
          <div class="--item__icon">
            <img src="~/assets/img/svg/header/watsapp-color.svg"/>
          </div>
          <div class="--item__number">
            {{ getPhoneNumberFormat(numberWhatsap) }}
          </div>
        </a>
      </div>
    </teleport>

  </header>
  <div :class="{ 'header__placeholder': isFixedHeader }" />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import HeaderDesktopTop from '~/components/common/header/HeaderDesktopTop.vue';
import HeaderDesktopMiddle from '~/components/common/header/HeaderDesktopMiddle.vue';
import HeaderDesktopBottom from '~/components/common/header/HeaderDesktopBottom.vue';
import { useMobileMenu } from '~/composables/useMobileMenu';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import {getPhoneNumberClear, getPhoneNumberFormat, useCitySelectModal} from '#imports';

const appConfig = useAppConfigStore();
const MobileMenu = defineAsyncComponent(() => import('~/components/common/header/HeaderMobile.vue'));
const SelectCityPopup = defineAsyncComponent(() => import('~/components/common/header/SelectCityPopup.vue'));
const isFixedHeader = ref<boolean>(false);
const isOpenMobileWhatsApp = ref<boolean>(false);
const isShowSearch = ref<boolean>(false);
const { isMobileMenuOpen } = useMobileMenu();
const { catalogTree } = catalogStore();
const { menuList } = cmsContentStore();
const { isSelectCityModalOpen } = useCitySelectModal();
const { config, vars } = storeToRefs(appConfig);
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

const handleScroll = (event: Event) => {
  const isFixed = ref(((event?.target as Document).scrollingElement?.scrollTop || 0) > 500);

  if (isFixedHeader.value !== isFixed.value) {
    isFixedHeader.value = isFixed.value;
  }
};

const openMobileWhatsApp = (): void => {
  console.log('openMobileWhatsApp')
  isOpenMobileWhatsApp.value = !isOpenMobileWhatsApp.value;
};

const toggleSearch = () => {
  isShowSearch.value = !isShowSearch.value;

  const searchElement: any = document.querySelector('.search-input__inpt');
  if (!searchElement) {
    return
  }
  searchElement.focus();
};

const closeSearch = () => {
  isShowSearch.value = false;
};
const url = useRequestURL();

const numberPhone = computed(() => {
  const _number: string = String(vars.value?.['Телефон'] || '');
  return getPhoneNumberClear(_number)
});
const numberWhatsap = computed(() => {
  const _number: string = String(vars.value?.['Whatsapp'] || '');
  return getPhoneNumberClear(_number)
});

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: config.value?.siteName || '',
  url: url.origin,
  address: {
    '@type': 'PostalAddress',
    postalCode: '105187',
    addressLocality: config.value?.address_city || 'Москва',
    streetAddress: vars.value?.['Адрес'] || 'Вольная улица, 28/4к1, вход 3Н.'
  },
  telephone: vars.value?.['Телефон'],
  email: vars.value?.email,
  logo: config.value?.logoFront || '',
  image: config.value?.logoFront || ''
}));
</script>

<style lang="scss">
.header-sticky {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  background: white;
}

.header__placeholder {
  height: 244px;

  @media screen and (max-width: 990px) {
    height: 127px;
  }
}

.header-mobile-phones {
  display: none;
  flex-direction: column;
  gap: 20px;
  background: white;
  position: absolute;
  top: 128px; left: 0; right: 0;
  z-index: 1000;
  border: 1px solid #3ea0f1;
  background: white;
  padding: 30px 40px;
  box-sizing: border-box;

  .--item {
    display: flex;
    align-items: center;
  }
  .--item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 17px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .--item__number {
    font-size: 18px;
    line-height: 26px;
    color: #1a1718;
  }
  &:after {
    content: "";
    position: absolute;
    left: 84px; bottom: calc(100% - 10px);
    width: 20px; height: 20px;
    background: white;
    border: 1px solid transparent;
    border-top-color: #3ea0f1;
    border-right-color: #3ea0f1;
    transform: rotate(-45deg);
    z-index: -1;
  }
  &.--fixed {
    position: fixed;
    top: 79px;
    &:after {
      left: 99px;
    }
  }
}

@media (max-width: 990px) {
  .header-mobile-phones {
    display: flex;
  }
}
</style>
