import { defineStore } from 'pinia'

interface INotificationItem {
  title?: string;
  message?: string;
  timeout?: number;
}

export const useCustomNotificationStore= defineStore({
  id: "customNotification",

  state: () => {
    return {
      isOpen: false,
      message: '',
      title: '',
    }
  },

  actions: {
    openCustomNotification: function(params: INotificationItem) {
      this.isOpen = true;
      this.title = params?.title || '';
      this.message = params?.message || '';

      setTimeout(() => {
        this.closeCustomNotification();
      }, (params?.timeout || 1500))
    },

    closeCustomNotification: function() {
      this.isOpen = false;
      this.title = '';
      this.message = '';
    },
  }
});
