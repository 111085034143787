import type { Ref } from 'vue';

interface IUseFastOrderModal{
  isSelectCityModalOpen:Ref<boolean>;
    openSelectCityModal:()=>void;
    closeSelectCityModal:()=>void;
}

export const useCitySelectModal = ():IUseFastOrderModal => {
  const isSelectCityModalOpen = useState<boolean>('isSelectCityModalOpen', () => false);

  const closeSelectCityModal = () => {
    isSelectCityModalOpen.value = false;
  };

  const openSelectCityModal = () => {
    isSelectCityModalOpen.value = true;
  };

  return {
    isSelectCityModalOpen,
    closeSelectCityModal,
    openSelectCityModal
  };
};
