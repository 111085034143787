<template>
  <div>
    <div class="content-search">
      <div
        class="content-search-input"
        :class="{'open': isShowSearch}"
      >
        <div class="icon-search">
          <img
            :src="SearchPrimaryIcon"
            alt="search icon"
          >
        </div>
        <SearchInput
          class="search-input__header"
          @closeSearch="closeSearch"
        />
        <div
          class="icon-close"
          @click="closeSearch"
        >
          <img
            :src="CloseIcon"
            alt="close icon"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchPrimaryIcon from '~/assets/img/svg/header/search-primary.svg';
import CloseIcon from '~/assets/img/svg/header/close-search.svg';
import SearchInput from '~/components/Search/SearchInput.vue';

interface IDialogSearchProps {
  isShowSearch: boolean;
}

withDefaults(defineProps<IDialogSearchProps>(), {
  isShowSearch: false
});

const emit = defineEmits<{
	closeSearch:[],
}>();

const closeSearch = () => {
  emit('closeSearch');
};
</script>

<style lang="scss">
.search-input {
  width: 100%;
}

.search-input__input {
  border: none;
  background: transparent;

  .search-input__img {
    display: none;
  }
}

.content-search {
  position: relative;
  height: 48px;
  z-index: 60;
}

.content-search-input {
  display: flex;
  align-items: center;
  width: 190px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #faf0fa;
  border-radius: 4px;
  padding: 0 14px;
  overflow: hidden;
  transition: all 0.5s;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  z-index: -1;

  @media screen and (max-width: 1200px) {
    width: 190px;
  }

  &.open {
    z-index: 1;
    width: 100%;
    background-color: white;
    border-color: red;
    overflow: initial;

  }

}

@media screen and (max-width: 760px) {
  .content-search {
    height: 44px;
  }

  .content-search-input {
    &.open {
      width: 100%;
    }

  }
}
</style>
