<template>
  <div class="wrapper">
    <div class="header-wrapper">
      <TheHeader />
    </div>

    <NuxtLayout>
      <main
        class="main"
        :class="{'home':isHome}"
      >
        <NuxtPage />
      </main>
    </NuxtLayout>

    <div class="footer-wrapper">
      <TheFooter />
    </div>
    <PopupGallery />
    <div v-if="isOpenCustomNotification">
      <CustomNotification />
    </div>
    <ScrollToTopButton
      v-if="scY > 500"
    />
    <GalleryComponent v-if="isGalleryOpen" />
    <template v-if="countersFooter">
      <div v-html="countersFooter" />
    </template>


    <Link rel="canonical" :href="linkCanonical"/>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { catalogStore } from '#sitis/stores/modules/catalog-store';

import { useRoute } from '#app';
import TheHeader from '~/components/common/header/TheHeader.vue';
import TheFooter from '~/components/common/footer/TheFooter.vue';
import ScrollToTopButton from '~/components/common/ScrollToTopButton.vue';
import useGalleryComponent from '~/composables/useGallery';
import CustomNotification from "~/components/widget/CustomNotification.vue";
import { useCustomNotificationStore } from '~/store/customNotification'

const GalleryComponent = defineAsyncComponent(() => import('~/components/widget/GalleryComponent.vue'));

const storeCatalog = catalogStore();
const cmsStore = cmsContentStore();
const configStore = appConfigStore();

const $route = useRoute();
const scY = ref<number>(0);
const scTimer = ref<ReturnType<typeof setTimeout>>();

onServerPrefetch(async () => {
  await cmsStore.getMenu();
  await configStore.getConfig();
  await storeCatalog.getCatalog();
});

useHead({
  title: 'Cilec',
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1 user-scalable=no' },
    { name: 'description', content: 'my content' }
  ],
  link: [
    // { rel: 'icon', type: 'image/svg+xml', href: `${configStore?.configList?.favicon}` },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', href: '/favicon-32x32.png' },
    { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
    { rel: 'shortcut icon', color: '#5bbad5', href: '/favicon.ico' }
  ]
});


const { isGalleryOpen } = useGalleryComponent();

const isHome = computed(() => $route.fullPath === '/');
const countersFooter = computed(() => configStore?.$state?.config?.counters?.footer || '');

const handleScroll = () => {
  if (scTimer.value) {
    return null;
  }

  scTimer.value = setTimeout(() => {
    scY.value = window.scrollY;
    clearTimeout(scTimer.value);
    scTimer.value = undefined;
  }, 100);
};

const linkCanonical: ComputedRef<string> = computed(() => {
  const event = useRequestEvent();
  const host = process.server ? event?.node.req.headers.host || '' : window.location.host;
  const [path] = useRoute().fullPath.split('?');
  return `https://${host}${path}`
});

const customNotificationStore = useCustomNotificationStore();
const isOpenCustomNotification = computed(() => {
  return customNotificationStore.$state.isOpen
})

const setYClid = () => {
  const yandexMetrika: any = useNuxtApp()?.payload?.config?.public?.yandexMetrika?.id || null;
  if (!yandexMetrika) {
    return;
  }

  const _window: any = window;
  if (!_window.ym) {
    return null
  }

  const clientId = _window?.Ya?._metrika?.counter.getClientID();
  if (!clientId) {
    return
  }

  const yclid: any = useCookie('yclid');
  yclid.value = clientId;
}

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('vite: preloadError', (event) =>{
    window.location.reload();
  })

  setYClid();
});
</script>

<style lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  padding: 22px 0 40px;
  min-height: 100%;
  flex: 1 0 auto;

  &.home {
    padding: 0 0 40px;
  }
}

.footer-wrapper {
  width: 100%;
  flex: 0 0 auto;
}

</style>
