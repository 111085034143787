<template>
  <footer class="footer">
    <div class="container container-small mobile-container-small">
      <div class="footer__mobile-title">
        Cilek — магазин детской мебели Cilek с доставкой по России
      </div>
      <div class="footer">
        <div class="footer__left">
          <div class="footer__title">Cilek — магазин детской мебели Cilek с доставкой по России</div>
          <div class="footer__left-menu">
            <ul class="footer__menu">
              <li
                v-for="item in menu"
                :key="`footer__menu-item-${item.id}`"
                class="footer__menu-item"
              >
                <nuxt-link :to="item.path">{{ item.title }}</nuxt-link>
              </li>
            </ul>
            <ul class="footer__menu">
              <li class="footer__text-item">
                Розничные продажи <br>
                <span v-html="addressRetail"/>
                <br>
                <br>
                Склад <br>
                <span v-html="addressStock"></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__right">
          <div v-if="false" class="footer__form">
            <div>
              <input
                v-model="emailAddress"
                class="input footer__form-input"
                type="email"
                name="email"
                placeholder="Email"
              >
              <button
                class="button footer__form-button"
                @click="sendSubsribe"
              >
                Подписаться
              </button>
            </div>
            <div class="footer__form-text">
              <p>Подпишитесь на нашу рассылку.</p>
              <p>Здесь новости и специальные предложения.</p>
            </div>
          </div>
          <div class="socials">
            <nuxt-link
              v-if="socialLink('odnoklassniki') !== ''"
              class="social"
              :to="socialLink('odnoklassniki')"
              target="_blank"
            >
              <img
                :src="PinterestIcon"
                alt="pinterest"
              >
            </nuxt-link>
            <nuxt-link
              v-if="socialLink('facebook') !== ''"
              class="social prohibitedSocial"
              :to="socialLink('facebook')"
              target="_blank"
            >
              <img
                :src="FacebookWhiteIcon"
                alt="facebook"
              >
            </nuxt-link>
            <div class="tooltip">{{tooltipText}}</div>
            <nuxt-link
              v-if="socialLink('instagram') !== ''"
              class="social prohibitedSocial"
              :to="socialLink('instagram')"
              target="_blank"
            >
              <img
                :src="InstagramWhiteIcon"
                alt="instagram"
              >
            </nuxt-link>
            <div class="tooltip">{{tooltipText}}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { storeToRefs } from 'pinia';
import { email } from '@vuelidate/validators';
import PinterestIcon from '~/assets/img/svg/footer/pinterest.svg';
import FacebookWhiteIcon from 'assets/img/svg/footer/facebook-white.svg';
import InstagramWhiteIcon from 'assets/img/svg/footer/instagram-white.svg';
import type {ComputedRef} from "vue";

const cmsStore = cmsContentStore();
const configStore = appConfigStore();

const {
  vars,
  config
} = storeToRefs(configStore);
const {
  $api,
  $toast
} = useNuxtApp();

const menu: ComputedRef<any[]> = computed(() => {
  const menus = storeToRefs(cmsStore)?.menus?.value?.footer?.items || [];
  return [...menus].filter((t: any) => {
    return Boolean(!t.isHidden && !t.isHome)
  })
});

const address: ComputedRef<string> = computed(() => {
  return vars?.value?.['Адрес с переносами'] || config?.value?.address || '';
});

const addressRetail: ComputedRef<string> = computed(() => {
  return vars?.value?.['Адрес 1 - Розничные продажи'] || '';
})

const addressStock: ComputedRef<string> = computed(() => {
  return vars?.value?.['Адрес 2 - Склад'] || '';
})

const emailAddress: Ref<string> = ref('');
const sendSubsribe = async () => {
  if (!emailAddress.value) {
    $toast.error('Введите Ваш Email адрес')
    return
  }

  const isValidEmail = email.$validator(emailAddress.value, '', '');
  if (!isValidEmail) {
    $toast.error('Email введен некорректно')
    return
  }

  const subscribeGroups = await $api.agent.get('/subscribe/groups').then((res: any) => {
    return res?._data || []
  }).catch(() => {
    return []
  });

  let errorsMessage: string[] = [];
  const functionSubcribe = async (groupId: number) => {
    const body = {
      "group_id": groupId,
      "email": emailAddress.value
    };
    const error = await $api.agent.post('/subscribe', {
      body
    }).then(() => null).catch((err) => err.response);
    if (!!error) {
      errorsMessage.push(error?._data?.[0]?.message || 'Ошибка сервера');
    }
  };
  for (const group of subscribeGroups) {
    await functionSubcribe(group.id);
  }
  if (errorsMessage.length > 0) {
    $toast.error(errorsMessage.join(";<br/>"));
    return
  }

  emailAddress.value = "";
  $toast.success("Подписка на новости успешно оформлена")
};
const socialLink = function(socialName: string){
  if (socialName==='instagram') {
    return config.value.instagram || '';
  }
  if (socialName === 'facebook') {
    return config.value.facebook || '';
  }
  if (socialName === 'odnoklassniki') {
    return config.value.odnoklassniki || '';
  }
}

const tooltipText = 'Признана экстремисткой организацией и запрещена на территории Российской Федерации';

</script>

<style lang="scss">
footer {
  background: #292929;
  padding-top: 27px;
  padding-bottom: 27px;
}

.footer__mobile-title {
  display: none;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 11px;
}

.container-small {
  width: 100%;
  max-width: 1240px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer__title {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 32px;
}

.footer__left-menu {
  display: flex;
}

.footer__menu {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 94px;
}

.footer__menu-item {
  font-size: 14px;
  line-height: 28px;

  & > a {
    color: #fff;
    text-decoration: none;
  }
}

.footer__text-item {
  font-size: 14px;
  line-height: 22px;
  color: #c4c4c4;
}

.footer__right {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 30%;
  padding-top: 4px;
}

.footer__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 37px;
}

.footer__form > div {
  display: flex;
  width: 100%;
  margin-bottom: 22px;
}

.footer__form-input {
  width: 100%;
  border-radius: inherit;
  font-size: 14px;
  line-height: 32px;
  padding: 0 20px;
  border: none;
}

.footer__form-button {
  font-size: 14px;
  line-height: 32px;
  color: rgb(255 255 255 / 80%);
  background: #368a55;
  border: none;
  cursor: pointer;
  padding: 0 6px;
}

.footer__form-text {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  color: #c4c4c4;
}

.socials {
  display: flex;
  position: relative;

  & .social {
    margin-right: 32px;
    width: 48px;
    display: block;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px;
    transition: all .2s linear;

    &:hover {
      opacity: .5;
      background-color: transparent;
      transition: all .2s linear;
    }

    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .tooltip {
    position: absolute;
    display: none;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
    top: -50px;
    max-width: 250px;
    font-size: 10px;
  }
  a:hover + .tooltip {
    display: block;
  }
}

@media screen and (max-width: 990px) {
  .mobile-container-small {
    padding: 0 11px;
  }

  .footer__mobile-title {
    font-size: 14px;
    line-height: 18px;
  }

  .footer__menu {
    margin-right: 8px;
  }

  .footer__menu-item {
    font-size: 13px;
    min-width: 150px;
  }

  .footer__form-text {
    font-size: 12px;
    flex-wrap: wrap;
    margin-top: 7px;

    & p {
      width: 100%;
      line-height: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer__mobile-title {
    display: flex;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer__title {
    display: none;
  }

  .footer__right {
    width: 100%;
    margin-bottom: 11px;
  }

  .footer__form {
    margin-bottom: 18px;
  }

  .footer__form > div {
    margin-bottom: 0;
  }
}
</style>
