import { useSitisCart, useSitisWish } from '#imports';

export default defineNuxtPlugin({
  name: 'initUser',
  async setup (nuxtApp) {
    const { getCart } = useSitisCart();
    const { getWishList } = useSitisWish();

    await getCart();
    await getWishList({ extensions: ['short_characteristics'] });
  }
}
);
