<template>
  <div
    id="headerBody"
    :class="{'search-active': isShowSearch }"
    class="header__body"
  >
    <div class="tm-header-center-container container mobile-container">
      <div class="header__search-line ">
        <DialogSearch
          :is-show-search="isShowSearch"
          @closeSearch="closeSearch"
        />
      </div>
      <div class="header__body-content">
        <div class="header__body-item header__body-left">
          <div class="header__body--show-sticky header__body--hide-medium">
            <div class="header__body--text-small">{{ workSchedule }}</div>
            <div class="header__body--text-small">Звонок бесплатный</div>
          </div>

          <div class="header__body--text-small header__body--hide-medium header__body--hide-sticky">
            {{ workSchedule }}
          </div>
          <div
            class="mobile-menu"
            @click="openMenu"
          >
            <img
              :src="MenuIcon"
              alt="menu icon"
            >
          </div>

          <div
            class="phone"
            @click="openMobileWhatsApp"
          >
            <a :href="phoneNumberLink">{{ phoneNumberText }}</a>
            <img
              :src="PhoneIcon"
              alt="phone icon"
            >
          </div>

          <div
            class="header__body--text-small header__body--hide-medium header__body--hide-sticky"
          >
            Звонок бесплатный
          </div>
        </div>

        <div class="header__body-item header__body-center">
          <nuxt-link
            to="/"
            class="logo header-logo"
          >
            <img
              :src="LogoIcon"
              alt="logo icon"
            >
            <span class="logo__span">Официальный дилер CILEK в России</span>
          </nuxt-link>
        </div>

        <div class="header__body-item header__body-right">
          <div class="header__shop-info-block">
            <nuxt-link
              class="header__shop-info"
              to="/wishlist"
            >
              <img
                :src="WishIcon"
                class="header__shop-info-icon"
                alt="wish icon"
              >
              <span class="header__shop-info-name">Избранное</span>
              <client-only>
                <span
                  id="wish-count"
                  class="header__shop-info-count"
                >{{ wishlistCount }}
                </span>
              </client-only>
            </nuxt-link>

            <nuxt-link
              class="header__shop-info"
              to="/cart"
            >
              <img
                :src="CartIcon"
                class="header__shop-info-icon"
                alt="cart icon"
              >
              <span class="header__shop-info-name">Моя корзина</span>
              <client-only>
                <span
                  id="countCart"
                  class="header__shop-info-count"
                >{{ cart.itemsCount }}</span>
              </client-only>
            </nuxt-link>
          </div>
        </div>
      </div>

      <div class="header__mobile-city header__mobile-city-address">
        <span>Официальный дилер CILEK в г.&nbsp;</span>
        <a
          class="header__mobile-city-address"
          @click.prevent="openSelectCityModal"
        >
          <span class="header__mobile-city-address--city">{{ currentCity }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MenuIcon from '~/assets/img/svg/header/menu.svg';
import PhoneIcon from '~/assets/img/svg/header/phone.svg';
import LogoIcon from '~/assets/img/svg/header/logo.svg';
import CartIcon from '~/assets/img/svg/header/cart.svg';
import WishIcon from '~/assets/img/svg/header/wish.svg';
import DialogSearch from '~/components/common/header/DialogSearch.vue';
import { cartStore } from '#sitis/stores/modules/cart-store';
import { useMobileMenu } from '~/composables/useMobileMenu';
import useSitisWish from '#sitis/composables/useSitisWish';
import type {ComputedRef} from "vue";
import {appConfigStore as useAppConfigStore} from "#sitis/stores/modules/app-store";
import {getPhoneNumberFormat, getPhoneNumberLink} from "~/utils/getPhoneNumber";
import {useCitySelectModal} from "~/composables/useCitySelectModal";

const { onOpenMobileMenu } = useMobileMenu();
withDefaults(defineProps<{
  isFixedHeader: boolean;
  isShowSearch: boolean;
}>(), {
  isFixedHeader: false,
  isShowSearch: false
});

const cart = cartStore();
const appConfig = useAppConfigStore();

const emit = defineEmits<{
	openMobileWhatsApp: [],
	closeSearch:[]
}>();
const { wishlistCount } = useSitisWish();
const openMenu = () => {
  document.body.style.overflow = 'hidden';
  document.body.classList.add('mobile');
  onOpenMobileMenu();
};
const openMobileWhatsApp = () => {
  emit('openMobileWhatsApp');
};

const closeSearch = () => {
  emit('closeSearch');
};


const phoneNumberText: ComputedRef<string | number> = computed(() => {
  const _phone = appConfig.vars?.['Телефон'] || "";
  return getPhoneNumberFormat(_phone);
});
const phoneNumberLink: ComputedRef<string> = computed(() => {
  const _phone = appConfig.vars?.['Телефон'] || "";
  return `tel:${getPhoneNumberLink(_phone)}`;
});

const workSchedule: ComputedRef<string> = computed(() => {
  const _workingHours = appConfig.config?.working_hours || '';
  if (!_workingHours) {
    return ""
  }
  return ['Ежедневно', _workingHours].join(' ');
})

const currentCity = computed(() => appConfig?.config?.city?.model?.name || 'Екатеринбург');
const { openSelectCityModal } = useCitySelectModal();

</script>

<style lang="scss">
.header__body {
  background: red;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}

.header__body-item {
  flex-basis: calc(100% / 3);
}

.header__search-line {
  transition: all 0.5s;
  margin-bottom: -45px;
  transform: translate(0, -60px);
  position: relative;
  z-index: 10;

  & input {
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: 0;
    padding: 10px 15px;
  }
}

.header__body-content {
  display: flex;
  transition: all 0.5s;
  //padding-bottom: 5px;
}

.header__body-left {
  padding: 15px 0;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  & .header__body--show-sticky {
    display: none;
  }

  & .header__body--text-small {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.9;
  }

  & .mobile-menu {
    display: none;
    background: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    width: 48px;
    height: 48px;

    > img {
      width: 48px;
      height: 48px;
    }
  }

  & .phone {
    margin-right: 43px;
    margin-bottom: 6px;
    margin-top: 6px;

    & img {
      display: none;
    }
  }
}

.search-active .header__body-content {
  transform: translate(0, 60px);
}

.search-active .header__search-line {
  display: block;
  transform: translate(0, 10px);
}

.header__body-center {
  display: flex;
  justify-content: center;

  & .header-logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      transform: none;
    }

    &:hover img {
      transform: scale(1.05);
    }

    & > img {
      width: 85px;
      height: 85px;
      margin-bottom: 5px;
      transition: all 0.5s;
    }

    & .logo__span {
      color: #fff;
      font-size: 14px;
      line-height: 21px;
      opacity: 0.9;
    }
  }
}

.header__body-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 23px 0;

  & .header__shop-info-block {
    margin-bottom: -10px;
  }

  & .header__shop-info {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    cursor: pointer;

    &:hover img {
      transform: translate(0, -3px) scale(1.1);
    }
  }

  & img {
    transition: all 0.5s;
  }

  & .header__shop-info-icon {
    flex-grow: 3;
    width: 24px;
    height: 24px;
  }

  & .header__shop-info-name {
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    margin-left: 22px;
  }

  & .header__shop-info-count {
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    margin-left: 19px;
    text-align: right;
  }
}

.header__mobile-city {
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 12px 0;
}

.header__mobile-city-address {
  color: #fff;
  font-size: 13px;
  text-align: center;
  line-height: 1.15;

  & .header__mobile-city-address--city {
    text-decoration: underline;
  }
}

.header-sticky {
  & .header__body--show-sticky {
    display: block;
  }

  & .header__body-left {
    padding: 10px 0;
    flex-direction: initial;
    flex-wrap: wrap;
  }

  & .header__body--hide-sticky {
    display: none;
  }

  & .header__body-left .phone {
    font-size: 18px;
    line-height: 23px;
    margin: 0;
    margin-left: 15px;
  }

  & .header__body-center .header-logo > img {
    width: 40px;
    height: 100%;
    margin-bottom: 3px;
  }

  & .header__body-center .header-logo .logo__span {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }

  & .header__body-right {
    padding: 10px 0;
  }

  & .header__body-right .header__shop-info-block {
    display: flex;
    margin-right: -10px;
  }

  & .header__body-right .header__shop-info {
    margin-right: 10px;
  }

  & .header__menu-item {
    padding: 5px 0;
  }

  & .header__menu-item a {
    font-size: 18px;
    line-height: 18px;
  }

  & .header__footer {
    background: #fff;
    box-shadow: 0 4px 5px -1px #00000057;
  }

}

@media screen and (max-width: 1199px) {
  .header__body-content {
    justify-content: space-between;
  }

  .header__body-center {
    flex-basis: 40px;

    & .header-logo .logo__span {
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
    }
  }

  .header__body-right .header__shop-info {
    font-size: 16px;
    line-height: 18px;

    & .header__shop-info-name {
      margin-left: 10px;
    }
  }

  .header__shop-info {
    font-size: 16px;
    line-height: 18px;
  }

  .header-sticky {
    & .header__body-left,
    & .header__body-right {
      flex-basis: calc(50% - 20px);
    }
  }
}

@media screen and (max-width: 990px) {
  .header__mobile-city {
    display: flex;
  }

  .header__body--hide-medium {
    display: none !important;
  }

  .header__body-center {
    & .header-logo {
      & img {
        width: 61px;
        height: 61px;
        min-width: 61px;
      }

      & .logo__span {
        display: none;
      }
    }
  }

  .header__body-left {
    padding: 15px 0;
    flex-direction: initial;
    align-items: center;

    & .mobile-menu {
      display: flex;
    }

    & .phone {
      border: 2px solid #fff;
      border-radius: 100%;
      margin-left: 0;
      position: relative;
      margin-bottom: 0;
      margin-top: 0;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '+ WhatsApp';
        position: absolute;
        top: 92%;
        left: 0;
        border: 1px solid #fff;
        background: #509646;
        border-radius: 100px;
        font-size: 9px;
        line-height: 13px;
        font-weight: 700;
        color: #fff;
        white-space: nowrap;
        padding: 0 3px;
      }

      & a {
        display: none;
      }

      & img {
        display: block;
        width: 43px;
        height: 43px;
        min-width: 43px;
      }
    }
  }

  .header__body-right {
    padding: 15px 0;

    & .header__shop-info-block {
      display: flex;
      margin-bottom: 0;
      margin-right: 0 !important;
    }

    & .header__shop-info {
      position: relative;
      border: 2px solid #fff;
      border-radius: 100%;
      margin-bottom: 0;
      margin-left: 8px;
      width: 48px;
      height: 48px;
      margin-right: 0 !important;
    }

    & .header__shop-info-name {
      display: none;
    }

    & .header__shop-info-icon {
      width: 24px;
      height: 24px;
    }

    & .header__shop-info-count {
      position: absolute;
      bottom: 68%;
      right: -2px;
      border: 1px solid #fff;
      background: #509646;
      border-radius: 100px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 700;
      color: #fff;
      white-space: nowrap;
      padding: 0 3px;
    }
  }

  .header-sticky {
    & .header__mobile-city {
      display: none;
    }
  }

  .header-mobile-phones {
    display: block;
  }

}
</style>
