const getPhoneNumberClear = (val: number | string): string => {
  let value = String(val);
  value = value.replace(/\D+/g, '');

  if (value[0] === '7') {
    value = '+' + value;
  }

  return value;
};
const getPhoneNumberFormat = (val:number | string) => {
  const cleaned = ('' + val).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  const isUsePlus = Boolean(cleaned[0] === '7');

  if (match) {
    const stringArray = [match[1], ' ', match[2], ' ', match[3], '-', match[4], '-', match[5]];
    if (isUsePlus) {
      stringArray.unshift('+');
    }

    return stringArray.join('');
  }

  return val;
};
const getPhoneNumberLink = (phoneNumber: string) => {
  const clearPhoneNumber = phoneNumber.replace(/\D+/g, '');
  if (clearPhoneNumber[0]) {
    return `+${clearPhoneNumber}`;
  }

  return clearPhoneNumber;
};
export {
  getPhoneNumberLink,
  getPhoneNumberClear,
  getPhoneNumberFormat
};
