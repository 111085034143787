import type { Ref } from 'vue';

interface IUseMobileMenu{
    isMobileMenuOpen:Ref<boolean>;
    onOpenMobileMenu:()=>void;
    onCloseMobileMenu:() => void;
}

export const useMobileMenu = ():IUseMobileMenu => {
  const isMobileMenuOpen = useState<boolean>('isMobileMenuOpen', () => false);

  const onOpenMobileMenu = () => {
    isMobileMenuOpen.value = true;
  };

  const onCloseMobileMenu = () => {
    isMobileMenuOpen.value = false;
  };
  return {
    isMobileMenuOpen,
    onOpenMobileMenu,
    onCloseMobileMenu
  };
};
