<template>
  <div class="custom-notification__popup-root">
    <div class="custom-notification__popup-bg"/>
    <div class="custom-notification__popup">
      <div class="custom-notification__title">{{ title }}</div>
      <div class="custom-notification__message"> {{ message }} </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCustomNotificationStore } from '~/store/customNotification'

const customNotificationStore = useCustomNotificationStore();

const message = computed(() => {
  return customNotificationStore.$state.message
})
const title = computed(() => {
  return customNotificationStore.$state.title
})
</script>

<style scoped>
.custom-notification__popup-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 997;
  align-items: center;
  padding: 15% 0;
  border-radius: 10px;
}
.custom-notification__popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s all;
}
.custom-notification__popup {
  background: var(--text-white);
  width: 30%;
  border-radius: 10px;
  //padding: 36px 40px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1;
  overflow: hidden;
}
.custom-notification__title {
  padding: 20px;
  background: var(--primary);
  color: var(--text-white);
  font-size: 23px;
  font-weight: 700;
}
.custom-notification__message {
  padding: 20px;
  color: var(--text-black);
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .custom-notification__popup {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .custom-notification__popup-root {
    padding: 30% 0;
  }
  .custom-notification__popup {
    width: 80%;
  }
}
</style>
