import { useState } from '#imports';
import type { Ref } from 'vue';

type GalleryComponentStateType = {
    imagesIds?: number[];
    videos?: { url: string }[];
    title?: string;
    description?: string;
    indexActiveSlide?: number;
}

type GalleryComponentType = {
    isGalleryOpen: Ref<boolean>;
    openGallery: (options: Partial<GalleryComponentStateType>) => void;
    galleryState: Ref<GalleryComponentStateType>;
    closeGallery: () => void;
}

function safelyAssign<T, K extends keyof T> (obj: T, key: K, value: T[K]): void {
  obj[key] = value;
}

export default function useGalleryComponent (): GalleryComponentType {
  const isGalleryOpen = useState<boolean>('isGalleryComponentOpen', () => false);
  const galleryState = useState<GalleryComponentStateType>('defaultGalleryState', () => ({}));

  function openGallery (options: Partial<GalleryComponentStateType>) {
    isGalleryOpen.value = true;

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        if (value !== undefined) {
          safelyAssign(galleryState.value, key as keyof GalleryComponentStateType, value);
        }
      });
    }
  }

  function closeGallery () {
    isGalleryOpen.value = false;
    galleryState.value = {};
  }

  return {
    isGalleryOpen,
    galleryState,
    openGallery,
    closeGallery
  };
}
