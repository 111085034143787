<template>
  <div
    @click="toTop"
  >
    <div class="button-default button  tm-btn-to-top">
      <img
        src="~/assets/img/svg/layout/arrow-up.svg"
        alt="up"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
const toTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
</script>

<style scoped lang="scss">
.tm-btn-to-top {
  position: fixed;
  bottom: 60px;
  right: 45px;
  background: #fff;
  z-index: 999;
  padding: 0;
  width: 49px;
  height: 49px;
  display: flex;
  justify-content: center;

  & > img {
    min-width: 45px;
    width: 45px;
    height: 45px;
  }

  &:hover {
    box-shadow: 0 0 5px 0;
  }
}
</style>
